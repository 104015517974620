<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item label="药品分类">
                <el-select v-model="searchForm.categoryId" placeholder="请选择" size="small" style="width: 150px"
                  @change="Search">
                  <el-option :label="item.categoryName" :value="item.categoryId" v-for="(item, index) in categoryData"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="失效日期">
                <el-date-picker v-model="searchForm.datepicker" type="date" placeholder="选择日期时间" align="right"
                  :picker-options="pickerOptions" value-format="yyyy-MM-dd" size="small">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="tableData.length > 0" :data="tableData" :fields="json_fields"
                  worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                  <el-button type="warning" size="mini" class="el-icon-download">导出当前数据</el-button>
                </download-excel>
              </el-form-item>
              <!-- <el-form-item>
                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData" worksheet="My Worksheet"
                  :header="title" :name="`${title}_All.xls`" class="excel">
                  <el-button type="danger" size="mini" class="el-icon-download">导出数据</el-button>
                </download-excel>
              </el-form-item> -->
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }"
          height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="drugName" label="药品名称">
            <template slot-scope="scope"> {{ scope.row.drugName }}
              <span v-if="scope.row.drugType=='T'">(统)</span>
              <span v-if="scope.row.drugType=='X'">(选)</span>
              <span v-if="scope.row.drugType=='J'">(精选)</span>
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="商品名" align="center"> </el-table-column>
          <el-table-column prop="validDate" label="失效日期" align="center" sortable>
            <template slot-scope="scope">
              <span>{{ scope.row.validDate.split('T')[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="catogoryName" label="药品分类" align="center"> </el-table-column>
          <el-table-column prop="specification" label="规格" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.specification">
                {{ scope.row.specification }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="productFactory" label="生产厂家" align="center"></el-table-column>
          <el-table-column prop="batchNumber" label="批号" align="center"></el-table-column>
          <el-table-column prop="inventoryNumber" label="库存" align="center"></el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal"
          layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient"
export default {
  data () {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      categoryData: [],
      searchForm: {
        categoryId: 0,
        datepicker: ""
      },
      endTime: '',
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now()
        },
        shortcuts: [{
          text: '一周内',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }, {
          text: '一个月内',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 30)
            picker.$emit('pick', date)
          }
        }, {
          text: '两个月内',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 30 * 2)
            picker.$emit('pick', date)
          }
        }, {
          text: '三个月内',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 30 * 3)
            picker.$emit('pick', date)
          }
        }, {
          text: '半年内',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 30 * 6)
            picker.$emit('pick', date)
          }
        }, {
          text: '1年内',
          onClick (picker) {
            const date = new Date()
            date.setFullYear(date.getFullYear() + 1)
            picker.$emit('pick', date)
          }
        }]
      },
      title: "效期提醒",
      DataCount: 0,
      json_fields: {
        '药品名称': 'drugName',
        药品分类: {
          filed: 'validDate',
          callback: (val) => {
            var validDate = val.validDate.split('T')[0]
            return validDate
          }
        },
        '药品分类': 'catogoryName',
        '规格': 'specification',
        '生产厂家': 'productFactory',
        '批号': 'batchNumber',
        '库存': 'inventoryNumber',
      },
      fileList: [],
    }
  },
  mounted () {
    const date = new Date()
    date.setTime(date.getTime() + 3600 * 1000 * 24 * 30)
    this.searchForm.datepicker = this.timefilters(date)
    this.getList()
    this.LoadCategory()
  },
  methods: {
    timefilters (val) {
      if (val == null || val == "") {
        return "-"
      } else {
        let d = new Date(val)   //val 为表格内取到的后台时间
        let month =
          d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1
        let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate()
        let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours()
        let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()
        let sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds()
        // let times = d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec
        let times = d.getFullYear() + '-' + month + '-' + day
        return times
      }
    },
    LoadCategory () {
      var _this = this
      _this.OutpatientDomain.GetOutpatientCategories(
        function (data) {
          _this.categoryData = data.data.categories
          _this.categoryData.unshift({ categoryId: 0, categoryName: '全部' })
        },
        function (err) {
          console.log(err)
        }
      )
    },
    changePage (pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    }, async fetchData () {
      var _this = this
      return await _this.getAllData()
    },

    getAllData () {
      var _this = this
      return new Promise((resolve, reject) => {
        var item = _this.searchForm
        _this.OutpatientDomain.DrugOrgValidDate(item.categoryId, item.endTime, this.pageIndex, _this.DataCount,
          function (data) {
            resolve(data.data.results)
          },
          function (err) {
            resolve('')
            console.log(err)
          })
      })

    },
    getList () {
      var _this = this
      _this.tableData = []
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.endTime = _this.searchForm.datepicker + " 23:59:59"
      } else {
        item.endTime = ""
      }
      _this.OutpatientDomain.DrugOrgValidDate(item.categoryId, item.endTime, this.pageIndex, null,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
          if (!item.keyWord) {
            _this.DataCount = data.data.dataTotal
          }
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Search (event) {
      this.pageIndex = 1
      this.getList()
    },
    // 导入数据
    handleExcel (file, fileList) {
      console.log(file.raw)
      let formData = new FormData() //声明一个FormDate对象
      formData.append("formFile", file.raw) //把文件信息放入对象中

    },
    //模板下载
    downloadTemplate () {
      let a = document.createElement("a")
      a.href = "./drug_import_template_with_branch.xls"
      a.download = "药品模板.xlsx"
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}

::v-deep .el-table {
  color: red !important;
}
</style>
